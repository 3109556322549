<template>
  <div>
    <XeNavBar logo-href="/activity" @logoClick="onLogoClick"> </XeNavBar>
    <LayoutCenter class="page-not-found">
      <AppCard sheet>
        <AppCardImageTitle
          :title="$t('PageNotFound.Title').value"
          :src="require('@galileo/assets/images/illustrations/telescope.svg')"
          alt="Man searching"
        >
          <p class="mb-6 text-secondary-text">
            {{ $t('PageNotFound.Description').value }}
          </p>
          <AppButton analytics-name="page-not-found-take-home-button" @click="onClick">
            {{ $t('PageNotFound.ButtonText').value }}
          </AppButton>
        </AppCardImageTitle>
      </AppCard>
    </LayoutCenter>
  </div>
</template>

<script>

import { useRouter } from '@galileo/composables/useRouter'

import LayoutCenter from '@galileo/components/LayoutCenter/LayoutCenter'
import XeNavBar from '@galileo/components/XeNavBar/XeNavBar'

import { AppCardImageTitle, AppCard, AppButton } from '@oen.web.vue2/ui'
import { useAuthStore, useI18nStore } from '@galileo/stores'
import { storeToRefs } from 'pinia'

export default {
  name: 'PageNotFound',
  components: {
    AppCardImageTitle,
    AppCard,
    LayoutCenter,
    AppButton,
    XeNavBar,
  },
  setup() {
    const { $t } = useI18nStore()
    const router = useRouter()
    const authStore = useAuthStore()

    const onLogoClick = async () => {
      await router.goBackTo(
        {
          name: 'Activity',
        },
        true
      )
    }

    const onClick = () => {
      const { isAuthenticated } = storeToRefs(authStore)
      if (isAuthenticated.value) {
        router.push({ name: 'Activity' })
      } else {
        router.push({ name: 'Login' })
      }
    }

    return {
      $t,
      onClick,
      onLogoClick,
    }
  },
}
</script>

<style scoped></style>
